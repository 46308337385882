<template>
  <div class="vqb-rule">
    <el-button :style="{ 'float': 'right' }" circle size="mini" type="danger" @click="remove" v-html="labels.removeRule"></el-button>

    <el-form-item :size="sizeControl" class="form-item-rule">
      <template slot="label">
        {{ rule.label }}
      </template>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-select v-if="typeof rule.operators !== undefined && rule.operators.length > 1" v-model="selectedOperatorIndex" @change="selectedChange">
            <el-option
              v-for="(operator, index) in rule.operators"
              :key="index"
              :value="operator.id"
              :label="operator.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <template v-if="availableOperationLogicValue['in'].indexOf(query.selectedOperator.id) !== -1">
            <el-button :style="{ position: 'absolute', right: '5px' }" circle size="small" type="success" icon="el-icon-plus" @click="addInput"></el-button>

            <template v-for="(queryValue, index) in query.value">
              <el-input
                :style="{ width: '89%', 'margin-right': '10px', 'margin-bottom': '10px' }"
                v-model="query.value[index]"
                type="text"
                :rows="2"
                :placeholder="rule.placeholder">
              </el-input>

              <el-button circle size="mini" type="default" icon="el-icon-delete" @click="removeInput(index)"></el-button>
            </template>
          </template>

          <template v-if="availableOperationLogicValue['other'].indexOf(query.selectedOperator.id) !== -1">
            <el-input
              v-if="rule.inputType === 'textarea'"
              v-model="query.value"
              type="textarea"
              :rows="2"
              :placeholder="rule.placeholder">
            </el-input>

            <el-input v-if="rule.inputType === 'text'" v-model="query.value" :placeholder="rule.placeholder"></el-input>

            <el-input-number
              v-if="rule.inputType === 'integer'"
              v-model="query.value"
              :style="{ width: '100%' }"
              :placeholder="rule.placeholder">
            </el-input-number>

            <el-input-number
              v-if="rule.inputType === 'float'"
              v-model="query.value"
              :precision="2"
              :step="0.1"
              :style="{ width: '100%' }"
              :placeholder="rule.placeholder">
            </el-input-number>

            <el-date-picker
              v-if="rule.inputType === 'date'"
              type="date"
              v-model="datetime"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :placeholder="rule.placeholder"
              @change="datetimeChanged">
            </el-date-picker>

            <el-date-picker
              v-if="rule.inputType === 'datetime'"
              type="datetime"
              v-model="datetime"
              format="dd.MM.yyyy hh:mm:ss"
              value-format="yyyy-MM-dd hh:mm:ss"
              :placeholder="rule.placeholder"
              @change="datetimeChanged">
            </el-date-picker>

            <el-time-picker
              v-if="rule.inputType === 'time'"
              v-model="datetime"
              :placeholder="rule.placeholder"
              @change="datetimeChanged">
            </el-time-picker>

            <el-checkbox v-if="rule.inputType === 'checkbox'" v-model="checked" :label="`Да / Нет`" @change="checkboxChange"></el-checkbox>

            <template v-if="isCustomComponent">
              <component :value="query.value" @input="updateQuery" :is="rule.component"></component>
            </template>
          </template>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import deepClone from '../utilities.js'

export default {
  name: 'ElementQueryBuilderRule',

  props: ['query', 'index', 'rule', 'styled', 'labels', 'sizeControl'],

  watch: {
    query () {
      this.localQuery = this.query
    }
  },

  data: function () {
    return {
      checked: true,
      datetime: '',
      localQuery: this.query,
      selectedOperatorIndex: typeof this.query.selectedOperator.id !== 'undefined' ? this.query.selectedOperator.id : null,
      availableOperationLogicValue: {
        is: ['is_not_null', 'is_null'],
        in: ['in', 'not_in'],
        other: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'like', 'not_like']
      },
      availableDateTimeRuleType: [
        'datetime', 'date', 'time'
      ]
    }
  },

  beforeMount () {
    if (this.rule.inputType === 'custom-component') {
      this.$options.components[this.id] = this.rule.component
    }
  },

  methods: {
    addInput () {
      this.localQuery.value.push('')
    },
    removeInput (index) {
      this.localQuery.value.splice(index, 1)
    },
    checkboxChange () {
      this.localQuery.value = this.checked
    },
    datetimeChanged () {
      this.localQuery.value = this.datetime
    },
    selectedChange () {
      let selectedOperator = this.rule.operators.find(operator => operator.id === this.selectedOperatorIndex)

      if (this.availableOperationLogicValue['in'].indexOf(selectedOperator.id) !== -1) {
        if (typeof this.query.value !== 'string' && !Array.isArray(this.query.value)) {
          this.$set(this.localQuery, 'value', [''])
        }

        if (typeof this.query.value === 'string') {
          this.$set(this.localQuery, 'value', this.query.value.split('; '))
        }
      } else {
        if (Array.isArray(this.query.value)) {
          if (['text'].indexOf(this.rule.inputType) !== -1) {
            this.$set(this.localQuery, 'value', this.query.value.join('; '))
          } else if (['integer', 'float'].indexOf(this.rule.inputType) !== -1) {
            this.$set(this.localQuery, 'value', 0)
          } else {
            this.$set(this.localQuery, 'value', null)
          }
        }
      }

      this.localQuery.selectedOperator = selectedOperator
    },
    remove: function () {
      this.$emit('child-deletion-requested', this.index)
    },
    updateQuery (value) {
      let updatedQuery = deepClone(this.query)
      updatedQuery.value = value
      this.$emit('update:query', updatedQuery)
    }
  },

  computed: {
    isCustomComponent () {
      return this.rule.inputType === 'custom-component'
    },

    selectOptions () {
      if (typeof this.rule.choices === 'undefined') {
        return {}
      }

      return this.rule.choices.reduce(function (groups, item, index) {
        let key = item['group']
        if (typeof key !== 'undefined') {
          groups[key] = groups[key] || []
          groups[key].push(item)
        } else {
          groups[index] = item
        }

        return groups
      }, {})
    }
  },

  mounted () {
    let updatedQuery = deepClone(this.query)

    if (this.availableDateTimeRuleType.indexOf(this.rule.inputType) !== -1) {
      this.datetime = typeof this.query.value !== 'string' ? '' : this.query.value
    }

    if (this.rule.inputType === 'checkbox') {
      if (typeof this.query.value !== 'boolean') {
        this.localQuery.value = false
      }

      this.checked = this.query.value
    }

    // Set a default value for these types if one isn't provided already
    if (this.query.value === null) {
      if (this.rule.inputType === 'checkbox') {
        updatedQuery.value = []
      }
      if (this.rule.inputType === 'select') {
        updatedQuery.value = this.rule.choices[0].value
      }
      if (this.rule.inputType === 'custom-component') {
        updatedQuery.value = null
        if (typeof this.rule.default !== 'undefined') {
          updatedQuery.value = deepClone(this.rule.default)
        }
      }

      this.$emit('update:query', updatedQuery)
    }
  }
}
</script>

<style scoped></style>
